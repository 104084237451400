@tailwind base;
@tailwind components;
@tailwind utilities;

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  border-top: 2px solid #043fb5;
  z-index: 2000;
}

.container2 {
  height: 100px;
  background-image: linear-gradient(to bottom, #09096c, rgb(48, 78, 197));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  box-shadow: 0 0 0.5rem saddlebrown;
}
.imagen {
  width: 160px; /* Ajusta el tamaño de la imagen según sea necesario */
  animation: crecerDisminuir 1.5s infinite; /* Animación continua */
}

.imagent {
  width: 60px; /* Ajusta el tamaño de la imagen según sea necesario */
  animation: crecerDisminuir 1.5s infinite; /* Animación continua */
}

@keyframes crecerDisminuir {
  0%,
  100% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(1.1); /* Tamaño aumentado */
  }
}

/* Core styles/functionality */
.tab {
  position: relative;
}
.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tab__content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s;
}
.tab input:checked ~ .tab__content {
  max-height: 100rem;
}

/* Visual styles */
.accordion {
  color: #227093;
  border: 2px solid;
  border-radius: 0.5rem;
  overflow: hidden;
}
.tab__label,
.tab__close {
  display: flex;
  color: white;
  background: #227093;
  cursor: pointer;
}
.tab__label {
  justify-content: space-between;
  padding: 1rem;
}
.tab__label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transform: rotate(90deg);
  transition: all 0.35s;
}
.tab input:checked + .tab__label::after {
  transform: rotate(270deg);
}
.tab__content p {
  margin: 0;
  padding: 1rem;
}
.tab__close {
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}
.accordion--radio {
  --theme: #2563eb;
}

/* Arrow animation */
.tab input:not(:checked) + .tab__label:hover::after {
  animation: bounce 0.5s infinite;
}
@keyframes bounce {
  25% {
    transform: rotate(90deg) translate(0.25rem);
  }
  75% {
    transform: rotate(90deg) translate(-0.25rem);
  }
}


.rotar {
  animation: girar 5s linear infinite; /* Duración de 5 segundos, animación lineal e infinita */
}

@keyframes girar {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
